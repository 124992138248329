@import '~bootstrap/scss/bootstrap.scss';

.transparent-dark {
  background: rgba(0, 0, 0, .8);
}

.line {
  background-color: #020514;
  color: #babcca;
  border-color: #06285e !important;
}

.line-text {
  color: white;
}

.text-gray {
  color: #babcca
}

.text-dark-gray {
  color: #7d8181
}

.portfolio-item {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  border: solid 2px #050505
}

.portfolio-title {
  color: #ffffff
}

.portfolio-title:hover {
  color: #bcc5d5;
}

.profile-img {
  min-height: 200px;
}
