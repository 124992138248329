// Override default variables before the import
$body-bg: #ffffff;

@media(min-device-width: 1176px) {
  body {
    background: #0a0f2c url('/static/media/field.webp') 50% no-repeat fixed;
    background-size: cover;
    display: block;
    height: 100vh;
  }
}

@import 'App';
